<template>
  <div class="settings">
    <app-redirect/>
  </div>
</template>
<script>

  import appRedirect from "../../../components/appRedirect";

  export default {
    name: "src-pages-body-workstation-buyerRequests-v1_mobile",
    components: {
      appRedirect
    },
  }

</script>
